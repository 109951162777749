'use strict';

import { isFuture, isNew } from './utils.js';

export const queryTag = ($el) => {
    let releaseDate = $el.getAttribute('data-releasedate'),
        postDate = $el.getAttribute('data-postdate'),
        kickstarter = $el.getAttribute('data-kickstarter');

    const setTag = ($el, text) => {
        $el.querySelector('span').innerHTML = text;
        $el.classList.remove('hidden');
    }
    if ( kickstarter === 'true' ) {
        return setTag($el, 'Kickstarter');
    }
    if ( releaseDate.length ) {
        // console.log(releaseDate);
        // there is a release date so test if it's a preorder
        if ( isFuture(releaseDate) ) {
            setTag($el, 'Preorder');
        } else if ( isNew(releaseDate, 2) ) {
            // it's not in the future so test if it's within the last 3 months
            setTag($el, 'New');
        }
    } else if ( isNew(postDate,1) ) {
        setTag($el, 'Recently Added');
    }
}
